import AlertError from "@/components/alert-error";
import { queryClient } from "@/store/react-query";
import type { Shop, ShopCollection, User } from "@/types";
import { useQuery } from "@tanstack/react-query";
import { actions } from "astro:actions";
import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import ProductCard from "./_product-card";

interface Props {
  shop: Shop;
  user?: User | null;
}

const Collections = ({ shop, user }: Props) => {
  const [collections, setCollections] = useState<ShopCollection[]>([]);
  const shopId = shop.id;

  const {
    data: collectionsData,
    error: fetchError,
    isLoading: isFetching,
  } = useQuery(
    {
      queryKey: ["collections", shopId],
      queryFn: () =>
        actions.frontend.getShopCollections({
          slug: shop.slug,
        }),
    },
    queryClient
  );

  useEffect(() => {
    if (collectionsData?.data?.collections) {
      setCollections(collectionsData.data.collections);
    }
  }, [collectionsData?.data?.collections]);

  return (
    <section className="container mx-auto py-8">
      {isFetching && <LoadingSkeleton />}

      {fetchError && <AlertError message="Error fetching products" />}

      {!isFetching && collections.length === 0 && (
        <p className="text-center">No products found.</p>
      )}

      <div className="flex flex-col gap-y-6 gap-x-4">
        {collections.map((collection) => {
          const activePublished = (collection.products ?? [])
            .filter((p) => p.is_published)
            .filter((p) => p.is_active);

          if (activePublished.length < 1) return null;

          return (
            <div key={collection.id} className="px-2">
              <div className="space-y-1 mb-4">
                <h1 className="text-2xl md:text-3xl xl:text-4xl font-semibold capitalize">
                  {collection.title.toLowerCase()}
                </h1>

                <hr className="border-slate-200 dark:border-slate-700 h-px mb-1" />
              </div>

              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-3 sm:gap-4">
                {activePublished.map((product) => (
                  <ProductCard
                    key={product.id}
                    product={product}
                    shop={shop}
                    user={user}
                  />
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

const LoadingSkeleton = () => {
  return (
    <div className="w-full h-[400px] flex items-center justify-center">
      <Loader2 size={100} className="animate-spin text-kp-primary" />
    </div>
  );
};

export default Collections;
